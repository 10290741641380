@use 'styles/variables' as var;

.achieve-section-container {
  max-width: var.$maximum-site-content-width;
  padding-left: var.$default-gutter-width;
  padding-right: var.$default-gutter-width;

  &[data-narrow='true'] {
    max-width: var.$maximum-site-content-width-narrow;
  }
}

@media screen and (min-width: var.$breakpoint-md) {
  .achieve-section-container {
    padding-left: var.$medium-and-up-gutter-width;
    padding-right: var.$medium-and-up-gutter-width;
  }
}
