@use 'styles/functions' as fn;
@use 'styles/variables' as var;

.icon-button {
  background-color: inherit;
  box-shadow: none;
  position: absolute;
  right: fn.spacing(1);
  top: fn.spacing(1);
  z-index: 1;
}

.dialog-content {
  overflow: hidden;
  padding: 0;
  margin: 0;
}

.button svg {
  color: var.$color-neutral-icon-hover-onlight;
}

.button [class*='endIcon'] {
  margin-left: fn.spacing(1);
}

.video-player-container {
  background-color: var.$color-neutral-black;
  padding-top: 50px;
}

// Targets the dialog modal backdrop
.dialog-container > div {
  background-color: var.$color-neutral-black;
}

@media only screen and (min-width: var.$breakpoint-md) {
  // Targets the dialog modal backdrop
  .dialog-container > div {
    background-color: rgb(0 0 0 / 65%);
  }

  .video-player-container {
    padding-top: 0;
  }
}
