.button {
  max-width: 400px;
}

.container-grid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 60px 0;
}

.text {
  text-align: center;
}
