@use 'styles/functions' as fn;

.iframe-wrapper {
  display: flex;
  width: 100vw;
  justify-content: center;
  height: fit-content;
  margin-bottom: fn.spacing(2);
}

.iframe-wrapper-external {
  width: 100%;
  height: fit-content;
}

.video-player-embed {
  position: relative;
  padding-bottom: 56.25%;
  height: fit-content;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
